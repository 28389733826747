import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DisclaimerPage } from 'src/pages/disclaimer/disclaimer';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },


  {
   path: 'matchdetails',
   loadChildren: () =>
     import('./matchdetails/matchdetails.module').then((m) => m.MatchdetailsPageModule),
  },
  {
    path: 'aboutus',
    loadChildren: () =>
      import('./aboutus/aboutus.module').then((m) => m.AboutusPageModule),
  },
  {
    path: 'teampreview',
    loadChildren: () =>
      import('./teampreview/teampreview.module').then((m) => m.TeampreviewPageModule),
  },
  {
    path: 'howtoplay',
    loadChildren: () =>
      import('./howtoplay/howtoplay.module').then((m) => m.HowtoplayPageModule),
  },
  // {
  //  // path: 'disclaimer', component:DisclaimerPage
  // },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'more',
    loadChildren: () =>
      import('./more/more.module').then((m) => m.MorePageModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'mymatches',
    loadChildren: () =>
      import('./mymatches/mymatches.module').then((m) => m.MymatchesPageModule),
  },
  {
    path: 'myleagues',
    loadChildren: () =>
      import('./myleagues/myleagues.module').then((m) => m.MyleaguesPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./signup/signup.module').then((m) => m.SignupPageModule),
  },
  {
    path: 'createteam',
    loadChildren: () =>
      import('./createteam/createteam.module').then(
        (m) => m.CreateteamPageModule
      ),
  },
  {
    path: 'playerprofile',
    loadChildren: () =>
      import('./playerprofile/playerprofile.module').then(
        (m) => m.PlayerprofilePageModule
      ),
  },
  {
    path: 'teamlistpostcreation',
    loadChildren: () =>
      import('./teamlistpostcreation/teamlistpostcreation.module').then(
        (m) => m.TeamlistpostcreationPageModule
      ),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./payment/payment.module').then((m) => m.PaymentPageModule),
  },
  {
    path: 'manageteam',
    loadChildren: () =>
      import('./manageteam/manageteam.module').then(
        (m) => m.ManageteamPageModule
      ),
  },
  {
    path: 'leaguedetail',
    loadChildren: () =>
      import('./leaguedetail/leaguedetail.module').then(
        (m) => m.LeaguedetailPageModule
      ),
  },
  {
    path: 'jackpot-popup',
    loadChildren: () =>
      import('./jackpot-popup/jackpot-popup.module').then(
        (m) => m.JackpotPopupPageModule
      ),
  },
  {
    path: 'prizestructure',
    loadChildren: () =>
      import('./prizestructure/prizestructure.module').then(
        (m) => m.PrizestructurePageModule
      ),
  },
  {
    path: 'playerpoints',
    loadChildren: () =>
      import('./playerpoints/playerpoints.module').then(
        (m) => m.PlayerpointsPageModule
      ),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./notifications/notifications.module').then(
        (m) => m.NotificationsPageModule
      ),
  },
  {
    path: 'faqs',
    loadChildren: () =>
      import('./faqs/faqs.module').then((m) => m.FaqsPageModule),
  },

  {
    path: 'termsconditions',
    loadChildren: () =>
      import('./termsconditions/termsconditions.module').then(
        (m) => m.TermsconditionsPageModule
      ),
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./change-password/change-password.module').then(
        (m) => m.ChangePasswordPageModule
      ),
  },
  {
    path: 'edittrivia',
    loadChildren: () =>
      import('./edittrivia/edittrivia.module').then(
        (m) => m.EdittriviaPageModule
      ),
  },
  {
    path: 'edit-profile',
    loadChildren: () =>
      import('./edit-profile/edit-profile.module').then(
        (m) => m.EditProfilePageModule
      ),
  },
  {
    path: 'privateleaguemodal',
    loadChildren: () =>
      import('./privateleaguemodal/privateleaguemodal.module').then(
        (m) => m.PrivateleaguemodalPageModule
      ),
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('./my-account/my-account.module').then(
        (m) => m.MyAccountPageModule
      ),
  },
  {
    path: 'trivia',
    loadChildren: () =>
      import('./trivia/trivia.module').then((m) => m.TriviaPageModule),
  },
  {
    path: 'joinleague',
    loadChildren: () =>
      import('./joinleague/joinleague.module').then(
        (m) => m.JoinleaguePageModule
      ),
  },
  {
    path: 'multitrivia',
    loadChildren: () =>
      import('./multitrivia/multitrivia.module').then(
        (m) => m.MultitriviaPageModule
      ),
  },
  {
    path: 'news',
    loadChildren: () =>
      import('./news/news.module').then((m) => m.NewsPageModule),
  },
  {
    path: 'newsdetails',
    loadChildren: () =>
      import('./newsdetails/newsdetails.module').then(
        (m) => m.NewsdetailsPageModule
      ),
  },
  {
    path: 'newsinterest',
    loadChildren: () =>
      import('./newsinterest/newsinterest.module').then(
        (m) => m.NewsinterestPageModule
      ),
  },
  {
    path: 'scorecard',
    loadChildren: () =>
      import('./scorecard/scorecard.module').then((m) => m.ScorecardPageModule),
  },
  {
    path: 'pointcard',
    loadChildren: () =>
      import('./pointcard/pointcard.module').then((m) => m.PointcardPageModule),
  },
  {
    path: 'swapplayer',
    loadChildren: () =>
      import('./swapplayer/swapplayer.module').then(
        (m) => m.SwapplayerPageModule
      ),
  },
  {
    path: 'editteam',
    loadChildren: () =>
      import('./editteam/editteam.module').then((m) => m.EditteamPageModule),
  },
  {
    path: 'paymentlist',
    loadChildren: () =>
      import('./paymentlist/paymentlist.module').then(
        (m) => m.PaymentlistPageModule
      ),
  },
  {
    path: 'paymentshow',
    loadChildren: () =>
      import('./paymentshow/paymentshow.module').then(
        (m) => m.PaymentshowPageModule
      ),
  },
  {
    path: 'previewteam-modal',
    loadChildren: () =>
      import('./previewteam-modal/previewteam-modal.module').then(
        (m) => m.PreviewteamModalPageModule
      ),
  },
  {
    path: 'balance',
    loadChildren: () =>
      import('./balance/balance.module').then((m) => m.BalancePageModule),
  },
  {
    path: 'withdraw',
    loadChildren: () =>
      import('./withdraw/withdraw.module').then((m) => m.WithdrawPageModule),
  },
  {
    path: 'winnersclub',
    loadChildren: () =>
      import('./winnersclub/winnersclub.module').then(
        (m) => m.WinnersclubPageModule
      ),
  },
  {
    path: 'scorecard-football',
    loadChildren: () =>
      import('./scorecard-football/scorecard-football.module').then(
        (m) => m.ScorecardFootballPageModule
      ),
  },
  {
    path: 'pointcard-football',
    loadChildren: () =>
      import('./pointcard-football/pointcard-football.module').then(
        (m) => m.PointcardFootballPageModule
      ),
  },
  {
    path: 'redirectleague',
    loadChildren: () =>
      import('./redirectleague/redirectleague.module').then(
        (m) => m.RedirectleaguePageModule
      ),
  },
  {
    path: 'refer-and-earn',
    loadChildren: () =>
      import('./refer-and-earn/refer-and-earn.module').then(
        (m) => m.ReferAndEarnPageModule
      ),
  },
  {
    path: 'privacypolicy',
    loadChildren: () =>
      import('./privacypolicy/privacypolicy.module').then(
        (m) => m.PrivacypolicyPageModule
      ),
  },
  {
    path: 'favsport',
    loadChildren: () =>
      import('./favsport/favsport.module').then((m) => m.FavsportPageModule),
  },
  {
    path: 'funfacts',
    loadChildren: () =>
      import('./funfacts/funfacts.module').then((m) => m.FunfactsPageModule),
  },
  {
    path: 'joinedmatches',
    loadChildren: () =>
      import('./joinedmatches/joinedmatches.module').then(
        (m) => m.JoinedmatchesPageModule
      ),
  },
  {
    path: 'joinleaguemodal',
    loadChildren: () =>
      import('./joinleaguemodal/joinleaguemodal.module').then(
        (m) => m.JoinleaguemodalPageModule
      ),
  },
  {
    path: 'point-system',
    loadChildren: () =>
      import('./point-system/point-system.module').then(
        (m) => m.PointSystemPageModule
      ),
  },
  {
    path: 'disclaimer',
    loadChildren: () =>
      import('./disclaimer/disclaimer.module').then(
        (m) => m.DisclaimerPageModule
      ),
  },
  {
    path: 'contactus',
    loadChildren: () =>
      import('./contactus/contactus.module').then((m) => m.ContactusPageModule),
  },
  {
    path: 'countdown',
    loadChildren: () => import('./countdown/countdown.module').then( m => m.CountdownPageModule)
  },
  {
    path: 'countdown1',
    loadChildren: () => import('./countdown1/countdown1.module').then( m => m.Countdown1PageModule)
  },
  {
    path: 'widget',
    loadChildren: () => import('./widget/widget.module').then( m => m.WidgetPageModule)
  },
  {
    path: 'eleventop',
    loadChildren: () => import('./eleventop/eleventop.module').then( m => m.EleventopPageModule)
  },
  {
    path: 'eleventopupdate',
    loadChildren: () => import('./eleventopupdate/eleventopupdate.module').then( m => m.EleventopupdatePageModule)
  },
  {
    path: 'editop',
    loadChildren: () => import('./editop/editop.module').then( m => m.EditopPageModule)
  },
  {
    path: 'newprediction',
    loadChildren: () => import('./newprediction/newprediction.module').then( m => m.NewpredictionPageModule)
  },
  {
    path: 'editnewprediction',
    loadChildren: () => import('./editnewprediction/editnewprediction.module').then( m => m.EditnewpredictionPageModule)
  },  {
    path: 'countdown2',
    loadChildren: () => import('./countdown2/countdown2.module').then( m => m.Countdown2PageModule)
  },
  {
    path: 'scorecardlive',
    loadChildren: () => import('./scorecardlive/scorecardlive.module').then( m => m.ScorecardlivePageModule)
  },
  {
    path: 'gstalert',
    loadChildren: () => import('./gstalert/gstalert.module').then( m => m.GstalertPageModule)
  },
  {
    path: 'lgradient',
    loadChildren: () => import('./lgradient/lgradient.module').then( m => m.LgradientPageModule)
  },
  {
    path: 'lgradientleft',
    loadChildren: () => import('./lgradientleft/lgradientleft.module').then( m => m.LgradientleftPageModule)
  },
  {
    path: 'top11leaguelist',
    loadChildren: () => import('./top11leaguelist/top11leaguelist.module').then( m => m.Top11leaguelistPageModule)
  },
  {
    path: 'myleaguetop11',
    loadChildren: () => import('./myleaguetop11/myleaguetop11.module').then( m => m.Myleaguetop11PageModule)
  },


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
