import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';

export interface AppConfig {
  customTxt: string;
  baseUrl: string;
 
}


@Injectable({
  providedIn: 'root'
})

export class AppConfigService {
  appConfig: AppConfig;

  constructor(private http: HttpClient) { }

  loadAppConfig() {
    this.http
        .get<AppConfig>("/assets/app.config.json")
        .pipe(
        )
        .subscribe(
            (data: AppConfig) => {
                 // Success
                this.appConfig = { ...data };
                console.log("client config loadded", this.appConfig);
            },
            (error) => {
                // Failure
                console.log(error);
            }
        );
}
}
