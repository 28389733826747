import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { Global } from '../../src/app/global';
import { Platform } from '@ionic/angular';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {
  info: any;
  constructor(public alertCtrl: AlertController, private route: ActivatedRoute, private elementRef:ElementRef,
    private loadingController: LoadingController, private location:Location, private toastCtrl: ToastController,public platform: Platform,
    private http:HttpClient, private globalData:Global, private router:Router, private verifyCtrl:AlertController) {
      
      var pathString = location.path();
      console.log('appComponent: pathString...');
      console.log(pathString); 
      let a = { name: 'mithili', mobile: 9922192853, email: 'mithilikakodkar@gmail.com', "acc_no":1181 };
      let data = btoa(JSON.stringify(a));
      
      this.insertUser();
      
    }
    
    initializeApp() {
      this.platform.ready().then(() => {
      // do some stuff
      })
      }
    ngOnInit() {
      this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          console.log("Active Component: ",e.url);
        }
      });
      if (!window.postMessage) {
        
      }
      else {
        if (window.addEventListener) {
          // For standards-compliant web browsers (ie9+)
          window.addEventListener("message", this.ReceiveMessage, false);
        }
        else {
          
          (<any>window).attachEvent("onmessage", this.ReceiveMessage);
        }
      }   
      localStorage.setItem("showgst","1");
    }
    
    ngAfterViewInit() {
      
    }
    
    
    // use uppercase and contains
    ReceiveMessage(evt:Event) {
      var message;
      
      message = 'Native indus says "' + evt['data'] + '" from "' + evt['origin'] + '"';
      
      // will receive message in ta
      var ta = document.getElementById("taRecvMessage");
      if (ta == null) {
        
      }
      else { document.getElementById("taRecvMessage").innerHTML = message; }
      
      //console.log("In app component evt[data]: ",  evt['data']);
      //  console.log("In app component mesage in app from native: ", message);
      //evt.source.postMessage("thanks, got it ;)", event.origin);
      
      let data = evt['data'];
      if (data !== null) {
        let obj_data;
        try { obj_data = JSON.parse(data); } catch (e)
        {
          obj_data = {};
          // console.log(e);
        } 
        if (obj_data['action'] == 'logout') {
          this.resetDatanLogout();
        }
       
        if (obj_data['theme'] == 'dark') {
          // this.changeThemeTo('dark');
          if (obj_data['theme'] == 'dark') {
            document.body.classList.remove('light');
          }
          else if (obj_data['theme'] == 'light') {
            document.body.classList.remove('dark');
          }
          document.body.classList.add(obj_data['theme']);
        }
        
        if (obj_data['theme'] == 'light') {
          // this.changeThemeTo('light');
          
          if (obj_data['theme'] == 'dark') {
            document.body.classList.remove('light');
          }
          else if (obj_data['theme'] == 'light') {
            document.body.classList.remove('dark');
          }
          document.body.classList.add(obj_data['theme']);
        }
        
        // console.log("classes after Applied :", document.body.classList[0],  document.body.classList.toString());
      }
    } 

    onDestroy(){
      localStorage.setItem("showgst","1");
    }
    
  changeThemeTo(theme) {
   
      if (theme == 'dark') {
        document.body.classList.remove('light');
      }
      else if (theme == 'light') {
        document.body.classList.remove('dark');
      }
      document.body.classList.add(theme);
    
    }
    
    resetDatanLogout() {
      
      let tab_sports = localStorage.getItem("tab_sports");
      let istabset = localStorage.getItem("is_tab_set")
      let actIndex = localStorage.getItem("tab_sport_id");
      
      //localStorage.clear();
      localStorage.setItem("tab_sports", tab_sports);
      localStorage.setItem("is_tab_set", istabset);
      localStorage.setItem("tab_sport_id", actIndex);
      // this.router.navigate(["login"]);
    }
    
    
    insertUser() {
      this.route.queryParams.subscribe(param => {
        
        
        // console.log("In ABC ", this.route.snapshot);
        // var url = new URL(window.location.href);
        // var lang = url.searchParams.get("info");
        // console.log(lang);
        
        
        var lang = param.info;
        // console.log("info",param.theme);        
        var theme = param.theme;
        //localStorage.setItem("theme", theme);
        var longitude = param.longitude;
        var lattitude = param.latitude;
        var app_type = param.app_type;
        var invite= param.invite;
        console.log("check invite: ", invite)
        if(invite!=undefined){
          localStorage.setItem("leagueid_invite", atob(invite))
        }
        // console.log(param, param.longitude, param.latitude);
        if (theme != undefined) {
          localStorage.setItem("theme", theme);
        }
        if (lang!= null || lang!= undefined) {
          let info_user = atob(lang + '');
          this.info = JSON.parse(info_user);
          console.log("print info: ",this.info)

          localStorage.setItem('fcm_id', this.info.fcm_id);
          //console.log("stored email", (localStorage.getItem('email')), "email in obj", this.info.email);
          // console.log((localStorage.getItem('email')) != this.info.email);
          console.log("JSON ",JSON.stringify(this.info));
          console.log("fcm_id ",this.info.fcm_id);
          if (this.info.token != undefined) {
            localStorage.setItem('opasToken', this.info.token);
            localStorage.setItem('app_type', this.info.app_type);
            console.log("check app_type: ",  this.info.app_type)

          }
          if ((localStorage.getItem('email')) != this.info.email) {
            this.clearSessionData();
            localStorage.setItem('app_type', this.info.app_type);
            console.log("check app_type: ",  this.info.app_type)
            localStorage.setItem('lat_location', lattitude);
            localStorage.setItem('lng_location', longitude);

            localStorage.setItem('username', this.info.name);
            localStorage.setItem('email', this.info.email);
            localStorage.setItem('mobile', this.info.mobile);
            localStorage.setItem('userid', this.info.user_id);
            localStorage.setItem('opasToken', this.info.token);
            localStorage.setItem('player_id', this.info.acc_no); 
            
            // if (this.info.isSocial) {  
            //   localStorage.setItem('username', this.info.name);
            //   localStorage.setItem('email', this.info.email);
            //   localStorage.setItem('mobile', this.info.mobile);
            //   localStorage.setItem('userid', this.info.user_id);
            //   localStorage.setItem('opasToken', this.info.token);
            //   localStorage.setItem('player_id', this.info.acc_no); 
            // } 
            this.login_new();
          }
          if (this.info.isSocial) {
            
            this.clearSessionData();
            localStorage.setItem('username', this.info.name);
            localStorage.setItem('email', this.info.email);
            localStorage.setItem('mobile', this.info.mobile);
            localStorage.setItem('userid', this.info.user_id);
            localStorage.setItem('opasToken', this.info.token);
            localStorage.setItem('player_id', this.info.acc_no);
            
            this.login_new();
          }
        } 
        
        if (theme != null || theme != undefined) {        
          this.changeThemeTo(theme);
        }
        
      })
    }
    
    SendMessage_withObj(obj) {
      let str_obj = JSON.stringify(obj);
      
      window.parent.postMessage(str_obj);
      // (window as any).webkit.messageHandlers.jsMessageHandler.postMessage(str_obj);
      try {
        ((window as any).webkit as any).messageHandlers.jsMessageHandler.postMessage(str_obj);
      }
      catch (er) {}
      if (!window['postMessage']) {}
      else {
        if (window.addEventListener) {
          window.addEventListener("message", this.ReceiveMessage, false);
        }
      }
    }
    
    
  clearSessionData() {
      
      localStorage.removeItem('username');
      localStorage.removeItem('email');
      localStorage.removeItem('mobile');
      localStorage.removeItem('userid');
      localStorage.removeItem('opasToken');
      localStorage.removeItem('player_id');
      
      localStorage.removeItem('token');
      localStorage.removeItem('referalCode');
      localStorage.removeItem('email');
      localStorage.removeItem('username');
    }
    
    login_new() {
      
      let url;
      if (this.info.mobile==0) {
        url =
        "user/usertoken/?merchant_id=bvcdrtyuikmnbvfdcvghj" +
          "&email=" + this.info.email + "&mobile=&name=" + this.info.name +
          "&player_id=" + localStorage.getItem('player_id') +
          "&refAcessToken="+localStorage.getItem('opasToken') ;   
      }
      else {
         url =
        "user/usertoken/?merchant_id=bvcdrtyuikmnbvfdcvghj" +
           "&email=" + this.info.email + "&mobile=" + this.info.mobile + "&name=" + this.info.name +
           "&player_id=" + localStorage.getItem('player_id')+
           "&refAcessToken="+localStorage.getItem('opasToken');
      }
      // let url =
      // "user/usertoken/?merchant_id=bvcdrtyuikmnbvfdcvghj" +
      // "&email="+this.info.email+"&mobile="+this.info.mobile+"&name="+this.info.name+"&player_id="+localStorage.getItem('player_id');
      // console.log("API HIT ", url);
      
      
      this.http.get(this.globalData.apiUrl + url).subscribe(data => {
        localStorage.setItem('isLogin', 'true');
        if (data['status']) {
          // this.showErrorToast("Login Successful");
          // console.log( JSON.stringify(this.info) , "api hit");
          // console.log("insert user called", JSON.stringify(data['userDetails']));
          localStorage.setItem('token',      data['userDetails']['access_token']);
          localStorage.setItem('referalCode',data['userDetails']['referalCode']);
          localStorage.setItem('email', data['userDetails']['email']);
          localStorage.setItem('username', data['userDetails']['username'].trim());
          // this.router.navigate(["tabs"]);
        }
        else {
          this.showErrorToast("Session Out...Please try logging in again!")
        }
      },err => {
        let obj = { action: "force_logout" };
        this.SendMessage_withObj(obj);
        this.showErrorToast("Unable to login. Please try again later!");
      });
    }
    
    showLoader(method) {
      this.loadingController.create({ message: "Loading", })
      .then((res) => {
        res.present();
        res.onDidDismiss().then((dis) => {
          // console.log("Loading dismissed!", method);
        });
      });
    }
    
    isValidEmail(email: string) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
    
    
    showErrorToast(data: any) {
      let toast = this.toastCtrl.create({
        message: data,
        duration: 3000,
        position: 'top'
      }).then(data => {
        data.onDidDismiss().then(() => {
          // console.log('Dismissed toast');
        });
        data.present();
      });
    }
    
    
    
    
    
  }
