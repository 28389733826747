import { Component, OnInit } from '@angular/core';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AlertController, LoadingController, MenuController,
  NavController, Platform, ToastController
} from '@ionic/angular';
// import firebase from 'firebase';
import { NativeStorage } from '@ionic-native/native-storage';
import { Global } from '../global';

import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
import { NavigationExtras, Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {


  isLoggedIn: boolean = false;
  email: any;
  password: any
  testForm: FormGroup;
  FB_APP_ID: number = 2166135106753962;
  posts: any;
  userData: any;
  isSocial: boolean = false;
  socialId: any = "";
  socialemail: any = "";
  googlesocialId: any = "";
  isLogin: boolean = false;
  emailforverification: any;
  loading: any;
  otpfromalert: any;
  mobilenoverifn: any;
  users: any;
  _mobileno: any;
  _email: any;
  category: any;
  fbEnabled: boolean = true;
  gEnabled: boolean = true;

  constructor(public nav: NavController, public router: Router, public verifyOtpCtrl: AlertController,
    public forgotCtrl: AlertController, public verifyCtrl: AlertController, public menu: MenuController,
    public toastCtrl: ToastController, private http: HttpClient, private fb: FormBuilder,
    // private googlePlus: GooglePlus, public fbs: Facebook,private facebook: Facebook,
    public globalData: Global,
     public loadingController: LoadingController,
    public nativeStorage: NativeStorage, public platform: Platform,
    ) {
    //let EMAILPATTERN = /^[a-zA-Z0-9_.+-]+@[a-zA-Z-]+.[a-zA-Z-.]+$/;
    //let EMAILPATTERN = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    let tab_sports = localStorage.getItem("tab_sports");
    let istabset = localStorage.getItem("is_tab_set")
    let actIndex = localStorage.getItem("tab_sport_id");

    localStorage.clear();
    this.nativeStorage.clear();

    localStorage.setItem("tab_sports", tab_sports);
    localStorage.setItem("is_tab_set", istabset);
    localStorage.setItem("tab_sport_id", actIndex); this.testForm = this.fb.group({
      //email: ['', Validators.compose([Validators.required, Validators.pattern(EMAILPATTERN)])],
      email: ['', Validators.compose([Validators.required, Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')])],
      // password: [''],
      // password: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(12)])],
      password: ['', Validators.compose([Validators.required])],
    });
    globalData.trackGA("login");
    
    if (localStorage.getItem('token') != null) {
      if ((<any>window).cordova) {
        if (this.platform.is('android')) {
          window.location.replace('file:///android_asset/www/index.html');
        }
        else {
          window.location.replace(window.location.origin + window.location.pathname);
        }
      }
      else {
        window.location.replace(window.location.origin);
      }
    }
  }

  login_new() {
    let url = "user/usertoken/?merchant_id=bvcdrtyuikmnbvfdcvghj&email=amit.thote@gmail.com&mobile=9823778113&name=amit";

    this.showLoader("loginAPI()");
    this.isLogin = false;
    
    this.http.get(this.globalData.apiUrl + url).subscribe(data => {
        this.posts = data;
         
        localStorage.setItem('isLogin', 'true');
      if (data['status']) {
        this.showErrorToast("Login Successful");
          localStorage.setItem('token',      data['userDetails']['access_token']);
          localStorage.setItem('referalCode',data['userDetails']['referalCode']);
          localStorage.setItem('email', data['userDetails']['email']);
          localStorage.setItem('username', data['userDetails']['username'].trim())
          this.router.navigate(["tabs"]);
        }
        else {
          if (data['error'] == "Email not verified.") {
            let verify = this.verifyCtrl.create({
              header: 'Email not verified!',
              message: "Please click on verfiy to resend email of verification",
              buttons: [
                {
                  text: 'CANCEL',
                  handler: data => {
                     
                  }
                },
                {
                  text: 'VERIFY NOW',
                  handler: data => {
                     
                    if (this.isValidEmail(this.emailforverification) && this.emailforverification != "") {
                       
                      this.http.get(this.globalData.apiUrl + 'user/resendemail/?email='
                        + this.emailforverification + '&merchant_id=' + this.globalData.merchantId,
                        {headers: new HttpHeaders(),}).subscribe(data => {   },
                          err => { this.showErrorToast(err) })
                    }
                    else {
                      this.showErrorToast('Invalid Email');
                      return false;
                    }
                  }
                }
              ]
            }).then(data => {
              data.present();
            });
          } else if (data['error'] == "OTP not verified." || data['action'] == "otp") {
            let verify = this.verifyCtrl.create({
              header: 'Mobile not verified!',
              message: "please enter mobile no for OTP verification",
              inputs: [
                {
                  name: 'mobile',
                  placeholder: 'Mobile',
                  type: 'tel'
                },
              ],
              buttons: [
                {
                  text: 'CANCEL',
                  handler: data => {}
                },
                {
                  text: 'VERIFY NOW',
                  handler: data => {
                    this.otpfromalert = data.mobile;
                    this.mobilenoverifn = data.mobile;
                    this._email = this.emailforverification;
                    this._mobileno = data.mobile;
                    // this.verifyMobNum();
                    this.resendOtp(data.mobile, this.emailforverification)
                    //this.mobOtpVerificationMethod(data.mobile);
                  }
                }
              ]
            }).then(data => {
              data.present();
            });
          } else {
            if (data['error'] == 'user_not_exists') {
              this.showErrorToast("No user found with this email id, kindly complete registration process");
              this.router.navigate(["login"]);
              return;
            }
            this.showErrorToast(data['error']);
          }
        }
      },err => {
           
          this.showErrorToast("Unable to login. Please try again later!");
        });
  }

    // login and go to home page
    login() {
      this.isSocial = false
      // this.loginAPI();
      this.login_new();
    }


  getConfig() {
    
    const headers = { 'tokenId': '123456', 'Accept-Language': 'en', 'Content-Type' : 'application/json' };
    const body = { userId: '8208079285' , userPWD: '8i9o0p', fcmId:''};
    //https://finaloverapi.diipl.in:6761/ts/sg/signon 
    //https://api.finalover.com:8443/ts/sg/signon
    //https://finaloverapi.diipl.in:6761/
    this.http.put<any>('https://finaloverapi.diipl.in:6761/ts/sg/signon', body, { headers })
      .subscribe(res => {
        if (res['msg']=="Success") {
        
          //get token API

          let urle:any;
        
             urle =
            "user/usertoken/?merchant_id=bvcdrtyuikmnbvfdcvghj" +
               "&email=" + res.ea.emailId + "&mobile=" + res.ea.mobileNo + "&name=" + res.ea.fullName +
               "&player_id=" + res.ea.playerId+
               "&refAcessToken="+res.refAcessToken;
        
         
          
          this.http.get(this.globalData.apiUrl + urle).subscribe(data => {
            localStorage.setItem('isLogin', 'true');
            if (data['status']) {
              // this.showErrorToast("Login Successful");
              // console.log( JSON.stringify(this.info) , "api hit");
              // console.log("insert user called", JSON.stringify(data['userDetails']));
              localStorage.setItem('token',      data['userDetails']['access_token']);
              localStorage.setItem('referalCode',data['userDetails']['referalCode']);
              localStorage.setItem('email', data['userDetails']['email']);
              localStorage.setItem('username', data['userDetails']['username'].trim());
              localStorage.setItem("vendor_id", "14")
              localStorage.setItem("lat_location", "15.29")
              localStorage.setItem("lng_location", "74.12")
              localStorage.setItem("app_type", "pro")
              localStorage.setItem("opasToken", res.refAcessToken)
              // this.router.navigate(["tabs"]);
            }
            else {
              // this.showErrorToast("Session Out...Please try logging in again!")
            }
          },err => {
            let obj = { action: "force_logout" };
            // this.showErrorToast("Unable to login. Please try again later!");
          });
          


        }

        
      }, err => {});
    // let bannerHeaders = new Headers();
    // bannerHeaders.append('Content-Type', 'application/json');
    // bannerHeaders.append('tokenId', '123456');
    // bannerHeaders.append('Accept-Language', 'en');

    // let bannerQp = new HttpParams();
    // bannerQp.append("sport_id", "1");
    // this.http.get(this.globalData.apiUrl +
    //   'config/getconfig/?merchant_id=' + this.globalData.merchantId +
    //   '&sport_id=1&access_token=' + localStorage.getItem('token'),
    //   { headers: bannerHeaders, params: bannerQp })
    //   .subscribe(res => {
    //     let data = res;
    //     if (data['status']) {
    //       this.gEnabled = data['configDetails']['google_enable'];
    //       this.fbEnabled = data['configDetails']['fb_enable'];
    //       localStorage.setItem("gEnabled", this.gEnabled + "");
    //       localStorage.setItem("fbEnabled", this.fbEnabled + "");
    //     }
    //   }, err => {});
  }

  ionViewWillEnter() {
    this.getConfig();
  }


  editprofile() {
    
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }




  logout() {
  }


  showLoader(method) {
    // this.loadingController.create({ message: "Loading", })
    //   .then((res) => {
    //     res.present();
    //     res.onDidDismiss().then((dis) => {
    //        
    //     });
    //   });
  }

  // loginAPI() {
  //   this.showLoader("loginAPI()");

  //   this.isLogin = false;
  //   let body = new FormData();
  //   let qp = new URLSearchParams();
  //   if (!this.isSocial) {
      
  //     body.append("identity", this.email);
  //     body.append("password", this.password);
  //     this.emailforverification = this.email;
  //   }
  //   else {
       
  //     body.append("fb_auth_uid", this.socialId);
  //     body.append("identity", this.socialemail);
  //     body.append("google_auth_uid", this.googlesocialId);
  //     body.append("password", "");
  //   }
    
  //   body.append("_format", "json");
    
    
  //   this.http.post(this.globalData.apiUrl + 'user/login/?merchant_id='
  //     + this.globalData.merchantId, body).subscribe(data => {
  //       this.posts = data;
         
        
  //       localStorage.setItem('isLogin', 'true');
  //       if (data['status']) {
           
  //         localStorage.setItem('token', data['access_token']);
  //         localStorage.setItem('referalCode', data['referalCode']);
  //         localStorage.setItem('email', this.testForm.value.email.trim());
  //         localStorage.setItem('username', data['username'].trim())

  //         let toast = this.toastCtrl.create({
  //           message: 'Login successful.',
  //           duration: 2000,
  //           position: 'top'
  //         }).then(res => {
  //           res.present();
  //           res.onDidDismiss().then((data) => {
               
  //             this.logout();
  //             if ((<any>window).cordova) {
  //               if (this.platform.is('android')) {
  //                 this.router.navigate(["tabs"]);
  //               }
  //               else {
  //                 //console.log("window.location.origin ", window.location.origin, " window.location.pathname: ", window.location.pathname);
  //                 // window.location.replace(window.location.origin + window.location.pathname);
  //                 this.router.navigate(["tabs"]);
  //               }
  //             }
  //             else {
                
  //               if (document.URL.includes("?")) {
  //                 let splitURL = document.URL.split("?");
  //                 // console.log("Split URL: " + splitURL[1]);
  //                 let fSplit = splitURL[1].split("&")
  //                 // console.log("All data : ", window.location.origin, ':==:', fSplit[0]);
  //                 this.router.navigate(["tabs"])
  //                 // window.location.replace(window.location.origin + '?' + fSplit[0]);
  //               }
  //               else {
  //                 // window.location.replace(window.location.origin);
  //                 this.router.navigate(["tabs"])
  //               }
  //             }
  //           });
  //         });
  //       }
  //       else {
  //         if (data['error'] == "Email not verified.") {
  //           let verify = this.verifyCtrl.create({
  //             header: 'Email not verified!',
  //             message: "Please click on verfiy to resend email of verification",
  //             buttons: [
  //               {
  //                 text: 'CANCEL',
  //                 handler: data => {
                     
  //                 }
  //               },
  //               {
  //                 text: 'VERIFY NOW',
  //                 handler: data => {
                     
  //                   if (this.isValidEmail(this.emailforverification) && this.emailforverification != "") {
                       
  //                     //   let body = new FormData();
  //                     // body.append('User[email]',this.emailforverification);

  //                     let http_param = new HttpParams();
  //                     // http_param.append(search, qp);
  //                     let param = { search: qp }
  //                     this.http.get(this.globalData.apiUrl + 'user/resendemail/?email='
  //                       + this.emailforverification + '&merchant_id=' + this.globalData.merchantId,
  //                       {
  //                         headers: new HttpHeaders(),
  //                       }
  //                     )
  //                       .subscribe(
  //                         data => {   },
  //                         err => {   });
  //                     // return true;
  //                   }
  //                   else {
  //                     this.showErrorToast('Invalid Email');
  //                     return false;
  //                   }
                     
  //                 }
  //               }
  //             ]
  //           }).then(data => {
  //             data.present();
  //           });

  //         } else if (data['error'] == "OTP not verified." || data['action'] == "otp") {
  //           let verify = this.verifyCtrl.create({
  //             header: 'Mobile not verified!',
  //             message: "please enter mobile no for OTP verification",
  //             inputs: [
  //               {
  //                 name: 'mobile',
  //                 placeholder: 'Mobile',
  //                 type: 'tel'
  //               },
  //             ],
  //             buttons: [
  //               {
  //                 text: 'CANCEL',
  //                 handler: data => {
                     
  //                 }
  //               },
  //               {
  //                 text: 'VERIFY NOW',
  //                 handler: data => {
                     
  //                   this.otpfromalert = data.mobile;
  //                   this.mobilenoverifn = data.mobile;
  //                   this._email = this.emailforverification;
  //                   this._mobileno = data.mobile;
  //                   // this.verifyMobNum();
  //                   this.resendOtp(data.mobile, this.emailforverification)
  //                   //this.mobOtpVerificationMethod(data.mobile);
  //                 }
  //               }
  //             ]
  //           }).then(data => {
  //             data.present();
  //           });
  //         } else {
  //           if (data['error'] == 'user_not_exists') {
  //             let toast = this.toastCtrl.create({
  //               message: "No user found with this email id, kindly complete registration process",
  //               duration: 3000,
  //               position: 'top'
  //             }).then(data => {
  //               data.present();
  //             });
              
  //             this.router.navigate(["signup"]);
  //             return;
  //           }
  //           let toast = this.toastCtrl.create({
  //             message: data['error'],
  //             duration: 3000,
  //             position: 'top'
  //           }).then(data => {
  //             data.onDidDismiss().then(() => {
                 
  //             });
  //             data.present();
  //           });
  //         }
  //       }
  //     },
  //       err => {
           
  //         let toast = this.toastCtrl.create({
  //           message: "Unable to login. Please try again later!",
  //           duration: 3000,
  //           position: 'top'
  //         }).then(data => {
  //           data.onDidDismiss().then(() => {
               
  //           });
  //           data.present();
  //         });
  //       });
  // }

  resendEmail() {
    let verify = this.verifyCtrl.create({
      header: 'Email not Verified?',
      message: "Please enter email id for verification",
      inputs: [
        {
          name: 'email',
          placeholder: 'email',
          type: 'email'
        },
      ],
      buttons: [
        {
          text: 'CANCEL',
          handler: data => {
             
          }
        },
        {
          text: 'VERIFY NOW',
          handler: data => {
            if (this.isValidEmail(data.email) && data.email != "") {
              this.resendEmailID(data.email);
            }
            else {
              this.showErrorToast("Please enter registered email id");
            }
            //this.resendEmailID(data.email);
            //this.mobOtpVerificationMethod(data.mobile);
          }
        }
      ]
    }).then(data => {
      data.present();
    });
  }

  resendEmailID(email) {
     
    this.showLoader("resendmailid");
    
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.get(this.globalData.apiUrl + 'user/resendemail?merchant_id=' +
      this.globalData.merchantId + '&email=' + email).subscribe(data => {
        
        if (data['status']) {
          this.showErrorToast(data['message']);
           
        }
        else {
          this.showErrorToast(data['error']);
           
        }
      });
  }

  isValidEmail(email: string) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  // forgotPass() {
  //   let forgot = this.forgotCtrl.create({
  //     header: 'Forgot Password?',
  //     message: "Enter your email address to get a password reset link.",
  //     inputs: [
  //       {
  //         name: 'email',
  //         placeholder: 'Email',
  //         type: 'email'
  //       },
  //     ],
  //     buttons: [
  //       {
  //         text: 'Cancel',
  //         handler: data => {
             
  //         }
  //       },
  //       {
  //         text: 'Send',
  //         handler: data => {
  //           if (this.isValidEmail(data.email)) {
               
  //             this.showLoader("forgotpassword");
  //             let body = new FormData();
  //             body.append('User[email]', data.email);
  //             let headers = new HttpHeaders();
  //             this.http.post(this.globalData.apiUrl + 'user/forgotpassword/?merchant_id='
  //               + this.globalData.merchantId,
  //               {
  //                 headers: new HttpHeaders(),
  //                 params: body
  //               }).subscribe(
  //                 data => {
                     
                     
  //                   if (data['status']) {

  //                     let toast = this.toastCtrl.create({
  //                       message: 'Email sent successfully',
  //                       duration: 3000,
  //                       position: 'top',
  //                       cssClass: 'dark-trans'
  //                     }).then(data => {
  //                       data.present();
  //                     });
  //                   }
  //                   else {
                       
  //                     let toast = this.toastCtrl.create({
  //                       message: data['error'],
  //                       duration: 3000,
  //                       position: 'top'
  //                     }).then(data => {
  //                       data.onDidDismiss().then(() => {
                           
  //                       });
  //                       data.present();
  //                     });
  //                   }
  //                 },
  //                 err => {
                     
  //                 });
  //             // return true;
  //           }
  //           else {
  //             this.showErrorToast('Invalid Email');
  //             return false;
  //           }
             
  //         }
  //       }
  //     ]
  //   }).then(data => {
  //     data.present();
  //   });
  // }

  public myFunc() {
    alert("Login Failed");
  }

  // public register() {
  //   let navigationExtras: NavigationExtras = {
  //     queryParams: {
  //       data: JSON.stringify("catid"),
  //     },
  //   };
  //   this.router.navigate(["signup"], navigationExtras);
  //   // this.nav.push('page-signup', { data: "catid" });
  // }

  /*doFbLogin()
  {
    // Login with permissions
    let permissions = new Array<string>();
    let nav = this.nav;
  let env = this;
    //the permissions your facebook app needs from the user
    permissions = ["public_profile","email","user_birthday"];

    this.fbs.login(permissions)
    .then( (res: FacebookLoginResponse) => {

        // The connection was successful
        if(res.status == "connected") {

            // Get user ID and Token
            var fb_id = res.authResponse.userID;
            var fb_token = res.authResponse.accessToken;

            // Get user infos from the API

            this.fbs.api("/"+fb_id+"/?fields=id,email,name,picture,gender,birthday",["public_profile"])
            .then((res) => {


               

              var id        = res.id;
              var email     = res.email;
              var name      = res.name;
              var gender    = res.gender;
              var birthday  = res.birthday;

              this.isSocial=true;
              this.socialId=res.id;
              this.socialemail=res.email;

              this.loginAPI();
            })
            .catch(e => {
               
            });
          }
    })
    .catch((e) => {});

  }*/

  public facebookLogin() {
    if ((<any>window).cordova) {
      this.doFacebookLoginApp();
    } else {

    }
  }

  public doFacebookLoginApp() {
    // this.facebook.login(['public_profile', 'user_friends', 'email'])
    //   .then(res => {
    //     if (res.status === "connected") {
    //       this.isLoggedIn = true;
    //       this.getUserDetail(res.authResponse.userID);
    //       console.log('Logged into Facebook', res);



    //     } else {
    //       this.isLoggedIn = false;
    //     }
    //   })
    //   .catch(e => console.log('Error logging into Facebook', e));
  }

  getUserDetail(userid) {
    // this.facebook.api("/" + userid + "/?fields=id,email,name,picture,gender", ["public_profile"])
    //   .then(res => {

    //      
    //     this.users = res;
    //     this.isSocial = true;
    //     this.socialId = res.id;
    //     this.socialemail = res.email;

    //     let stringToSplit = res.name;
    //     let x = stringToSplit.split(" ");
    //     localStorage.setItem('social_login_fname', x[0].trim());
    //     localStorage.setItem('social_login_lname', x[1].trim());
    //     localStorage.setItem('social_login_email', res.email.trim());
    //     localStorage.setItem('social_id', res.id);
    //     localStorage.setItem('social_type', "fb");
    //     this.emailforverification = res.email;

    //     this.loginAPI();
    //   })
    //   .catch(e => {
    //      
    //   });
  }


  doGooglePlusLogin() {
    if ((<any>window).cordova) {
      this.doGooglePlusLoginApp();
    } else {

    }
  }
  doGooglePlusLoginApp() {
   
    // this.googlePlus.login({})
    //   .then(res => {
    //      
      

    //     this.isSocial = true;
    //     this.googlesocialId = res.userId;
    //     console.log(" user in gplusmethod app" + res.userId);

    //     this.socialemail = res.email;
    //     this.emailforverification = res.email;

    //     let stringToSplit: string;
    //     stringToSplit = res.displayName;
    //     let y = stringToSplit.split(" ")
    //     var spaceCount = (stringToSplit.split(" ").length);
    //     let fname: string = "";
    //     let lname: string = "";
    //     for (let i = 0; i < spaceCount; i++) {
    //       if (i < spaceCount - 1) {
    //         fname = fname + " " + y[i];

    //       }
    //       else {
    //         lname = y[i];

    //       }
    //     }

    //     localStorage.setItem('social_login_fname', fname.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').trim());
    //     localStorage.setItem('social_login_lname', lname.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').trim());
    //     localStorage.setItem('social_login_email', res.email.trim());
    //     localStorage.setItem('social_id', res.userId);
    //     localStorage.setItem('social_type', "g+");

    //     this.loginAPI();

    //   })
    //   .catch(
    //     err => console.error(err));
  }


  showErrorToast(data: any) {
    let toast = this.toastCtrl.create({
      message: data,
      duration: 3000,
      position: 'top'
    }).then(data => {
      data.onDidDismiss().then(() => {
         
      });
      data.present();
    });
  }

  verifyMobNum() {

    let forgot = this.verifyOtpCtrl.create({
      header: 'Type OTP here',
      inputs: [
        {
          name: 'otp',
          placeholder: 'Enter OTP Here',
          type: 'number'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: data => {
             
          }
        },
        {
          text: 'Resend',
          role: 'resend',
          handler: data => {
             
            this.resendOtp(this._mobileno, this._email);
            // this.resendOtp();
          }
        },
        {
          text: 'Submit',
          role: 'submit',
          handler: data => {
             
            this.otpfromalert = data.otp;
            this.mobOtpVerificationMethod(this.mobilenoverifn);
          }
        },
      ]
    }).then(data => {
      data.present();
    });
  }

  resendOtp(mobile, email) {

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let qp = new URLSearchParams();
    // qp.append("merchant_id", this.globalData.merchantId);
    // qp.append("access_token", localStorage.getItem('token'));
    this.http.get(this.globalData.apiUrl + 'user/resendotp?merchant_id=' +
      this.globalData.merchantId + '&mobileNo=' + mobile + '&email=' + email + '&vendor=' +
      localStorage.getItem("vendor"),
    ).subscribe(data => {
       
      if (data['status']) {
         
        let toast = this.toastCtrl.create({
          message: data['message'],
          duration: 3000,
          position: 'top'
        }).then(data => {
          data.onDidDismiss().then(() => {
             
          });

          data.present();
        });
         
        this.verifyMobNum();
      }
      else {
         
        let toast = this.toastCtrl.create({
          message: data['error'],
          duration: 3000,
          position: 'top'
        }).then(data => {
          data.onDidDismiss().then(() => {
             
          });
          data.present();
        });
         
      }
    });
  }


  mobOtpVerificationMethod(mobilenoz) {
   
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let qp = new URLSearchParams();
    // qp.append("merchant_id", this.globalData.merchantId);
    // qp.append("access_token", localStorage.getItem('token'));
   
    this.http.get(this.globalData.apiUrl + 'user/otpactivate?merchant_id=' + this.globalData.merchantId + '&mobileNo=' + mobilenoz + '&vendor=' + localStorage.getItem("vendor") + '&otp=' + this.otpfromalert).subscribe(data => {
       
      if (data['status']) {
         
         
        let toast = this.toastCtrl.create({
          message: data['message'],
          duration: 3000,
          position: 'top'
        }).then(data => {
          data.onDidDismiss().then(() => {
             
          });
          data.present();
        });


        // this.loginAPI();// old login commented

        // this.nav.push(LoginPage);
        // this.router.navigate(["page-signup"]);
      }
      else {
         
        let toast = this.toastCtrl.create({
          message: data['error'],
          duration: 3000,
          position: 'top'
        }).then(data => {
          data.onDidDismiss().then(() => {
             
          });
          data.present();
        });
         
        this.verifyMobNum();
      }
      // let body = new FormData();
      // let headers = new Headers();
      // let options = new RequestOptions({ headers: headers });
      // body.append("otp",this.otpfromalert);
    });
  }

  ngOnInit() {
  }

  gotoProfile() {
    this.router.navigate(['/profile']);
  }

}
