import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

export class Global {




  //----------- Url ends for Ionic ( Fantasy and predictor ) ------------------
  // demo api
  // public baseUrl = "https://irapi.demowebapps.com";
  
  // test server
   public baseUrl = "https://testapi.finalover.com";//"https://testthinos3api.indusgames.com";

  // production server 
  //  public baseUrl = "https://api.finalover.com:2087";  

  //------------------------ Url ends for Octopus APIs --------------------------------// 
    
  // dev
   public opasUrl = 'https://finaloverapi.diipl.in:6761/';


 
  // prod
  //  public opasUrl = 'https://api.finalover.com:8443/';


  //------------------------ Url ends for Octopus APIs --------------------------------// 
  public cmsUrls = 'https://testthinos3cms.indusgames.com'; // https://indusgames.com
 // public proUrl = 'https://indusapi.demowebapps.com'; //  not used
  public version = '/v1/';
  public apiUrl = this.baseUrl + '/index.php' + this.version;
  public merchantId = 'bvcdrtyuikmnbvfdcvghj'; 
  public appVersion = '2.8.11';
  public mainUrl = 'https://testthinos3.indusgames.com';
  public trackingId = 'UA-139155234-1';
  public vendor = 'fun2020';
  public nodeapiUrl = 'http://winnertvnodeapi.diipl.in';


  public customTxt = "Please Update the App."
  // public merchantId = 'bvcdrtyuikmnbvfdcvghj';


  //   //  public baseUrl = "https://indusapi.demowebapps.com";
  //   public baseUrl = 'http://winnertvapi.demowebapps.com';  //TT
  //   // public baseUrl = 'http://qawinnertvapi.diipl.in';
  //   // public baseUrl = "https://testthinosapi.indusgames.com"  //production
  //   // public cmsUrls = 'https://indusgames.com'
  //   public cmsUrls = 'http://winnertv.demowebapps.com'; // https://indusgames.com
  //   //  public cmsUrls = 'https://testthinoscms2.indusgames.com';
  //   // public cmsUrls = 'https://indus.demowebapps.com';
  //   public proUrl = 'https://indusapi.demowebapps.com'; //  not used
  //   public version = '/v1/'
  //   public apiUrl = this.baseUrl + '/index.php' + this.version;
  //   public nodeapiUrl = "http://winnertvnodeapi.diipl.in";
  //   public merchantId = 'bvcdrtyuikmnbvfdcvghj';
  //   // public merchantId = 'abcasdfghjklzxcvbnm';
  //   public appVersion = "1.0.0";
  //   // public mainUrl = 'https://indusapp.demowebapps.com';
  //   public mainUrl = 'https://testthinos.indusgames.com';
  //   //https://www.indusgames.com
  //   // public mainUrl = 'https://www.indusgames.com';
  //   //  public mainUrl = 'https://testthinos2.indusgames.com';
  //   public trackingId = "UA-139155234-1";
  //   // public vendor = "indus11232";
  //   public vendor = "fun2020";
  //   // public trackingId = "UA-140259469-1";
  //   //K3NC3XFQR5WF5YH4KC2K
  //  // gaa: GoogleAnalytics;


  //------------------------------------------------------------------------
//NEW URLs                                                                                           PATH

//https://testapi.finalover.com                                                      /var/www/finalover/api/web

//https://testadmin.finalover.com                                                /var/www/finalover/backend/web

//https://teststorage.finalover.com                                              /var/www/finalover/storage/web

//https://test.finalover.com                                                            /var/www/finalover_app

  constructor() {} // public gaa: GoogleAnalytics,
  trackGA(screen) {}
}
