import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
// import { InAppBrowser, } from "@ionic-native/in-app-browser/ngx";
// import { Ionic2RatingModule } from 'ionic2-rating';
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { Global } from './global';
import { Clipboard } from '@ionic-native/clipboard';

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule, FormGroup } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
// import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { FlurryAnalytics, FlurryAnalyticsObject, FlurryAnalyticsOptions } from '@ionic-native/flurry-analytics';
import { WindowRef } from "./WindowRef";

//import { HttpConfigInterceptor } from './shared/interceptor';
//import { PincodePageModule } from './pincode/pincode.module';
import { LoginPageModule } from "./login/login.module";
// import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook';
// import { GoogleAnalytics } from '@ionic-native/google-analytics';
import { NativeStorage } from '@ionic-native/native-storage';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';

import { SafePipe } from './safe.pipe';
import { SocialSharing } from '@ionic-native/social-sharing';
import { InAppBrowserOptions, InAppBrowser } from '@ionic-native/in-app-browser';
import { CountdownPage } from "./countdown/countdown.page";
import { BooleanService } from "src/services/boolean.service";
import { AppConfigService } from "src/app/app-config.service";
import {  APP_INITIALIZER } from '@angular/core';

// import { NgxImageZoomModule } from '../../node_modules/ngx-image-zoom';

@NgModule({
  
  declarations: [AppComponent, SafePipe],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule, 
    //FormsModule,
    // PincodePageModule,
    LoginPageModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
          return () => {                    
              return appConfigService.loadAppConfig();
          };
      },
  },
    HttpClientModule,
    Global,
    DatePipe,
    BooleanService,
    // Facebook,
    // GooglePlus,
    NativeStorage,
    Clipboard,
    // GoogleAnalytics,
    SocialSharing,
    InAppBrowser,
    FlurryAnalytics,
    WindowRef,

    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpConfigInterceptor,
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule { }
